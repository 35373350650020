import Image from "next/legacy/image";
import FloatingButtonImage from "public/assets/coco-copy.svg";
import CocoStudyingLight from "public/assets/coco-studying-light.svg";
import CocoStudyingDark from "public/assets/coco-studying.svg";
import React from "react";
import {
  FloatingButton,
  FloatingButtonContent,
  TanyaSearchBlockContainer,
  TanyaSearchBlockWrapper,
  TanyaSearchButton,
  TanyaSearchDetailsWrapper,
  TanyaSearchInput,
  TanyaSearchInputContainer,
  TanyaSearchMascotWrapper,
  TanyaSearchSubTitle,
  TanyaSearchTitle
} from "./tanya-search-block.styles";
import { ITanyaSearchBlockProps } from "./tanya-search-block.types";
import { useTanyaSearchBlock } from "./use-tanya-search-block";

const TanyaSearchBlock: React.FC<ITanyaSearchBlockProps> = ({
  bgTheme = "dark",
  showFloatingButton,
  isTanyaHome
}) => {
  const {
    searchQuery,
    tanyaSearchInputRef,
    showTanyaFloatingButton,
    handleSearchQuery,
    handleSubmitQuery,
    handleFloatingButtonClick
  } = useTanyaSearchBlock();

  return (
    <TanyaSearchBlockWrapper $bgTheme={bgTheme} id="tanya-search-block">
      <TanyaSearchBlockContainer>
        <TanyaSearchMascotWrapper>
          <Image
            src={bgTheme === "light" ? CocoStudyingLight : CocoStudyingDark}
            alt="Coco Studying using Tanya"
            objectFit="fill"
            layout="responsive"
            width={300}
            height={223}
          />
        </TanyaSearchMascotWrapper>
        <TanyaSearchDetailsWrapper>
          <TanyaSearchTitle $bgTheme={bgTheme} as={isTanyaHome ? `h1` : `h2`}>
            Mau Tanya apa hari ini?
          </TanyaSearchTitle>
          <TanyaSearchSubTitle $bgTheme={bgTheme}>
            Semua video solusi CoLearn udah dicek kebenarannya, loh!
          </TanyaSearchSubTitle>

          <TanyaSearchInputContainer>
            <TanyaSearchInput
              data-testid="tanyaSearch-fieldbox"
              ref={tanyaSearchInputRef}
              placeholder="Ketik soal Matematika, Fisika, atau Kimia di sini"
              value={searchQuery}
              onChange={handleSearchQuery}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  handleSubmitQuery();
                }
              }}
            />
            <TanyaSearchButton
              data-testid="tanyaSearch-button"
              disabled={searchQuery === "" || searchQuery === null}
              onClick={() => handleSubmitQuery()}
            >
              Cari video solusi
            </TanyaSearchButton>
          </TanyaSearchInputContainer>
        </TanyaSearchDetailsWrapper>
      </TanyaSearchBlockContainer>
      {showFloatingButton && (
        <FloatingButton
          $show={showTanyaFloatingButton}
          onClick={handleFloatingButtonClick}
        >
          <Image
            src={FloatingButtonImage}
            alt="Coco wants to ask something"
            layout="intrinsic"
            width={66}
            height={50}
          />
          <FloatingButtonContent>Mau tanya apa hari ini?</FloatingButtonContent>
        </FloatingButton>
      )}
    </TanyaSearchBlockWrapper>
  );
};

export { TanyaSearchBlock };
