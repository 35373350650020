import React from "react";
import Image from "next/legacy/image";
import ColearnAppAndSubjectsImg from "public/assets/home/CoLearn-aplikasi-belajar-Matematika-Fisika-Kimia.png";

import {
  Description,
  DownloadCTAContainer,
  Heading,
  ImageWrapper,
  MoneyBackGuaranteeContainer,
  Wrapper
} from "./money-back-guarantee-app-download.styles";
import { DownloadAppCTAs } from "components/download-app-ctas/download-app-ctas";

export const MoneyBackGuaranteeAppDownload: React.FC = () => {
  return (
    <Wrapper>
      <MoneyBackGuaranteeContainer>
        <div>
          <Heading>
            Bimbel online pertama dengan garansi uang kembali 100%
          </Heading>
          <Description>Yuk, download sekarang!</Description>
          <DownloadCTAContainer>
            <DownloadAppCTAs />
          </DownloadCTAContainer>
        </div>
        <ImageWrapper>
          <Image
            src={ColearnAppAndSubjectsImg}
            alt="CoLearn aplikasi belajar Matematika Fisika Kimia"
            layout="responsive"
            sizes="(min-width: 768px) 50vw, 100vw"
            priority
          />
        </ImageWrapper>
      </MoneyBackGuaranteeContainer>
    </Wrapper>
  );
};
