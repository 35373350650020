import styled from "styled-components";
import { breakpoints, zIndex } from "styles";
import { Container } from "styles/common/container";

export const TanyaSearchBlockContainer = styled(Container)`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.tablet} {
    padding: 50px 20px;
    flex-direction: row;
    justify-content: center;
  }
`;

export const TanyaSearchBlockWrapper = styled.section<{ $bgTheme: string }>`
  background: ${({ theme, $bgTheme }) =>
    $bgTheme === "light" ? theme.colors.yellow[100] : theme.colors.brand[500]};
`;

export const TanyaSearchMascotWrapper = styled.div`
  position: relative;
  width: 240px;
  height: 180px;

  @media ${breakpoints.tablet} {
    width: 280px;
    height: 210px;
  }
`;

export const TanyaSearchTitle = styled.h2<{ $bgTheme: string }>`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  margin: 8px 0;
  text-align: center;
  color: ${({ theme, $bgTheme }) =>
    $bgTheme === "light" ? theme.colors.brand[500] : "inherit"};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    text-align: left;
  }
`;

export const TanyaSearchSubTitle = styled.p<{ $bgTheme: string }>`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  margin: 4px 0;
  text-align: center;
  color: ${({ theme, $bgTheme }) =>
    $bgTheme === "light" ? theme.colors.neutral[900] : "inherit"};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
    text-align: left;
  }
`;

export const TanyaSearchDetailsWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};

  @media ${breakpoints.tablet} {
    flex-grow: 1;
    margin-left: 24px;
  }
`;

export const TanyaSearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  padding: 12px;
  border: 3px solid ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 8px;
  margin: 16px 0 0 0;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export const TanyaSearchInput = styled.input`
  border: none;
  resize: none;
  --webkit-appearance: none;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.black};
  min-height: 60px;
  line-height: 1.5;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral[300]};
    vertical-align: middle;
  }
  &:focus {
    outline: none;
  }

  @media ${breakpoints.tablet} {
    min-height: 0;
  }
`;

export const TanyaSearchButton = styled.button`
  --webkit-appearance: none;
  border: 0;
  box-shadow: none;
  margin: 8px 0 0 0;
  padding: 12px 0;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.brand[500]};
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    background: ${({ theme }) => theme.colors.brand[400]};
    cursor: not-allowed;
  }

  @media ${breakpoints.tablet} {
    max-width: 210px;
    padding: 12px 24px;
    white-space: nowrap;
    margin: 0;
  }
`;

export const FloatingButton = styled.button<{ $show: boolean }>`
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, ${(props) => (props.$show ? "0" : "200%")});
  transition: transform 1s ease-in-out;
  width: 253px;
  height: 50px;
  background: ${({ theme }) => theme.colors.brand[500]};
  border-radius: 50px;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.floatingButton};

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const FloatingButtonContent = styled.span`
  width: 180px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`;
