import React from "react";
import type { IIcon } from "./icons.types";

const CheckCircle: React.FC<IIcon> = ({
  width = 20,
  height = 21,
  color = "currentColor"
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0.5C7.34784 0.5 4.8043 1.55356 2.92893 3.42892C1.05357 5.30429 0 7.84784 0 10.5C0 13.1522 1.05357 15.6957 2.92893 17.5711C4.8043 19.4464 7.34784 20.5 10 20.5C12.6522 20.5 15.1957 19.4464 17.0711 17.5711C18.9464 15.6957 20 13.1522 20 10.5C20 7.84784 18.9464 5.30429 17.0711 3.42892C15.1957 1.55356 12.6522 0.5 10 0.5ZM15.77 9.36L9.55 15.5C9.2834 15.7652 8.92586 15.9189 8.55 15.93C8.17553 15.9216 7.81818 15.7715 7.55 15.51L4.33 12.39C4.21813 12.2785 4.12936 12.146 4.06879 12.0002C4.00823 11.8543 3.97705 11.6979 3.97705 11.54C3.97705 11.3821 4.00823 11.2257 4.06879 11.0798C4.12936 10.934 4.21813 10.8015 4.33 10.69L4.75 10.26C4.8608 10.1469 4.99305 10.0571 5.139 9.99574C5.28495 9.93441 5.44168 9.90283 5.6 9.90283C5.75832 9.90283 5.91504 9.93441 6.061 9.99574C6.20695 10.0571 6.3392 10.1469 6.45 10.26L8.61 12.36L13.78 7.25999C14.0064 7.03648 14.3118 6.91116 14.63 6.91116C14.9482 6.91116 15.2535 7.03648 15.48 7.25999L15.9 7.69C16.0979 7.93138 16.1949 8.23992 16.1707 8.55113C16.1465 8.86235 16.0029 9.15216 15.77 9.36Z"
      fill={color}
    />
  </svg>
);

export { CheckCircle };
