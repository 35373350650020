import React, { useRef, PropsWithChildren } from "react";
import trackEvent from "services/mixpanel/mixpanel";
import { paketPageEvents } from "services/mixpanel/web-checkout-events";
import { useAuth } from "stores/auth/auth-context";
import { createPayloadForWebCheckoutTrackingEvent } from "utils/mixpanel/web-checkout/web-checkout-mixpanel-utils";
import {
  StyledSliderWrapper,
  StyledScroll,
  StyledNext,
  StyledPrev
} from "./scroll-snap-carousel.styles";
import { Chevron } from "components/icons/chevron";
import { useCardCarousel } from "hooks/use-card-carousel/use-card-carousel";
import { IComponentWithSource } from "types/common/common.types";

export const ScrollSnapSlider: React.FC<
  PropsWithChildren<IComponentWithSource>
> = ({ children, eventSource, height, width }) => {
  const scrollSnapSliderRef = useRef<HTMLUListElement>(null);
  const { user } = useAuth();

  const onScrollToPrevPage = () => {
    const eventName = paketPageEvents.testimonialLeftHozScrollClicked;
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user
    });
    trackEvent({
      eventName,
      payload: { ...payload, source: eventSource || "" }
    });
  };

  const onScrollToNextPage = () => {
    const eventName = paketPageEvents.testimonialRightHozScrollClicked;
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user
    });
    trackEvent({
      eventName,
      payload: { ...payload, source: eventSource || "" }
    });
  };

  const { hasLeftItems, hasRightItems, handlePrev, handleNext } =
    useCardCarousel({
      carouselRef: scrollSnapSliderRef,
      onScrollToPrevPage,
      onScrollToNextPage
    });

  return (
    <StyledSliderWrapper>
      <StyledPrev onClick={handlePrev} $isDisabled={hasLeftItems}>
        <Chevron />
      </StyledPrev>
      <StyledScroll
        containerWidth={width}
        containerHeight={height}
        ref={scrollSnapSliderRef}
      >
        {children}
      </StyledScroll>
      <StyledNext onClick={handleNext} $isDisabled={hasRightItems}>
        <Chevron direction="right" />
      </StyledNext>
    </StyledSliderWrapper>
  );
};
