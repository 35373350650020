import React from "react";
import Image from "next/legacy/image";
import {
  Description,
  Heading,
  TrustedByCTA,
  TrustedByCard,
  TrustedByCardContainer,
  TrustedByCardDesc,
  TrustedByCardTitle,
  TrustedByContainer,
  Wrapper
} from "./trusted-by-section.styles";
import FullMarksImage from "public/assets/home/logos/96-percent-murid-nilainya-naik-setelah-ikut-Bimbel-CoLearn.svg";
import FingerHeartImage from "public/assets/home/logos/5-juta-murid-sudah-download-aplikasi-CoLearn-di-Play-Store.svg";
import FlagImage from "public/assets/home/logos/2500-lebih-murid-Bimbel-CoLearn-tersebar-di-seluruh-Indonesia.svg";
import PlayImage from "public/assets/home/logos/600-ribu-lebih-video-pembahasan-di-fitur-Tanya.svg";

export const TrustedBySection: React.FC = () => {
  return (
    <section>
      <TrustedByContainer>
        <Wrapper>
          <div>
            <Heading>
              CoLearn sudah dipercaya oleh jutaan murid Indonesia
            </Heading>
            <Description>CoLearn bisa bantu wujudkan impianmu!</Description>
          </div>
          <TrustedByCardContainer>
            {trustedByCardData.map(({ img, title, desc }) => (
              <TrustedByCard key={title}>
                <Image src={img} alt={title} width={80} height={80} />
                <div>
                  <TrustedByCardTitle>{title}</TrustedByCardTitle>
                  <TrustedByCardDesc>{desc}</TrustedByCardDesc>
                </div>
              </TrustedByCard>
            ))}
          </TrustedByCardContainer>
          <TrustedByCTA href="/bimbel" data-testid="info-daftar-button">
            Cari info daftar Bimbel
          </TrustedByCTA>
        </Wrapper>
      </TrustedByContainer>
    </section>
  );
};

const trustedByCardData = [
  {
    img: FingerHeartImage,
    title: "5.000.000+ murid",
    desc: "Download di Google Playstore"
  },
  {
    img: FlagImage,
    title: "2500+ murid bimbel",
    desc: "Tersebar di seluruh Indonesia"
  },
  {
    img: PlayImage,
    title: "600.000+",
    desc: "Video pembahasan di fitur Tanya "
  },
  {
    img: FullMarksImage,
    title: "96% murid",
    desc: "Nilainya naik setelah ikut Bimbel "
  }
];
