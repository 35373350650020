import styled from "styled-components";
import Image from "next/legacy/image";
import { breakpoints } from "styles";

export const BimbelSectionContainer = styled.div`
  padding: 40px 0px 40px 0px;
  @media ${breakpoints.tablet} {
    padding: 40px 0px 40px 0;
  }
  @media ${breakpoints.laptop} {
    padding: 80px 0px 100px 0;
  }
`;

export const BimbelGradeHeading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["xl"]};
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    text-align: center;
    margin-bottom: 50px;
  }
`;

export const BimbelGradeCard = styled.li`
  border-radius: 12px;
  list-style: none;
  box-shadow: 0px 0px 32px rgba(114, 131, 158, 0.16);
  height: auto;
  scroll-snap-align: center;
  position: relative;
  margin-right: 30px;

  @media ${breakpoints.tablet} {
    margin-right: 50px;
  }
`;

export const BimbelGradeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 20px;
`;

export const BimbelGradeCuriculumText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
  }
`;

export const BimbelCuriculumDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
  @media ${breakpoints.tablet} {
    margin-top: 20px;
  }
`;

export const ColorText = styled.span`
  color: ${({ theme }) => theme.colors.brand["500"]};
`;

export const BimbelImageCard = styled(Image)`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const CardGradeText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
  }
`;

export const CardGradeSubText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
  }
`;

export const SubjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 16px;
`;

export const CardButtonContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: auto;
  width: 90%;
  margin-left: 5%;
  bottom: 10px;
`;

export const BimbelGradeCardDiv = styled.div`
  @media ${breakpoints.mobile} {
    height: 260px;
    width: 280px;
  }

  @media ${breakpoints.tablet} {
    height: 280px;
    width: 300px;
  }
`;

export const GradeContainer = styled.div`
  position: absolute;
  top: 0;
  padding: 0px 10px 10px 20px;
`;

export const FaqButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
