import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const TrustedByContainer = styled(Container)`
  padding: 40px 16px;

  @media ${breakpoints.tablet} {
    padding: ${rem(80)};
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 24px;

  @media ${breakpoints.tablet} {
    gap: 32px;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, auto);
  }
`;

export const Heading = styled.h3`
  margin: 0 0 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 28px;
  font-size: ${rem(22)};
  text-align: center;
  text-wrap: balance;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 42px;
    text-align: left;
  }
`;

export const Description = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.neutral[700]};
  text-align: center;

  @media ${breakpoints.tablet} {
    text-align: left;
  }
`;

export const TrustedByCTA = styled.a`
  display: block;
  width: 234px;
  background-color: ${({ theme }) => theme.colors.brand[500]};
  color: ${({ theme }) => theme.colors.white};
  padding: 12px 24px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;
  text-align: center;
  margin: 0 auto;

  @media ${breakpoints.tablet} {
    margin: unset;
    grid-area: 2/1/3/2;
  }
`;

export const TrustedByCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media ${breakpoints.tablet} {
    gap: 32px;
    grid-area: 1/2/4/4;
  }
`;

export const TrustedByCard = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 30px ${({ theme }) => theme.colors.transparent.white10};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${breakpoints.tablet} {
    flex-direction: row-reverse;
    align-items: center;
  }
`;

export const TrustedByCardTitle = styled.p`
  margin: 12px 0;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  line-height: 22px;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
    line-height: 28px;
    margin: 0 0 8px;
  }
`;

export const TrustedByCardDesc = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.neutral[700]};
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  line-height: 24px;
`;
