import { useEffect, useState, useCallback, RefObject } from "react";
import { IUseCardCarousel } from "./use-card-carousel.types";

const TIMEOUT = 3000;

const useCardCarousel = <T extends HTMLElement = HTMLElement>({
  carouselRef,
  onScrollToPrevPage,
  onScrollToNextPage,
  autoScroll
}: {
  carouselRef: RefObject<T>;
  eventSource?: string;
  onScrollToPrevPage?: () => void;
  onScrollToNextPage?: () => void;
  autoScroll?: boolean;
}): IUseCardCarousel => {
  const [cardSize, setCardSize] = useState(0);
  const [hasLeftItems, setHasLeftItems] = useState(true);
  const [hasRightItems, setHasRightItems] = useState(false);
  const [hidePagination, setHidePagination] = useState(false);

  const handleScrollToNextPage = () => {
    onScrollToNextPage && onScrollToNextPage();
    return carouselRef.current?.scrollBy({
      left: cardSize,
      behavior: "smooth"
    });
  };

  const handleScrollToPrevPage = () => {
    onScrollToPrevPage && onScrollToPrevPage();

    return carouselRef.current?.scrollBy({
      left: -cardSize,
      behavior: "smooth"
    });
  };

  const startAutoScroll = useCallback(
    (direction: string) => {
      const intervalId = window.setInterval(
        direction === "right" ? handleScrollToNextPage : handleScrollToPrevPage,
        TIMEOUT
      ); // 3 sec interval
      return intervalId;
    },
    [handleScrollToNextPage]
  );

  const stopAutoScroll = useCallback((intervalId: number) => {
    clearInterval(intervalId);
  }, []);

  const handleScroll = useCallback(() => {
    if (carouselRef.current) {
      setHasLeftItems(carouselRef.current.scrollLeft === 0);
      setHasRightItems(
        Math.ceil(
          carouselRef.current.scrollLeft + carouselRef.current.clientWidth
        ) === carouselRef.current.scrollWidth
      );
    }
  }, [carouselRef]);

  useEffect(() => {
    const carouselNode = carouselRef.current;
    const cardItem = carouselNode?.children[0] as HTMLLIElement;
    setHidePagination(
      carouselRef.current?.clientWidth === carouselRef.current?.scrollWidth
    );
    setCardSize(cardItem?.clientWidth + 30);
    carouselNode?.addEventListener("scroll", handleScroll);
    return () => {
      carouselNode?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, carouselRef]);

  useEffect(() => {
    let autoScrollInterval: number | null = null;
    let timer: ReturnType<typeof setTimeout> | null = null;

    if (autoScroll) {
      if (!autoScrollInterval && !timer) {
        autoScrollInterval = startAutoScroll("right");
      }

      if (hasRightItems) {
        if (autoScrollInterval) {
          stopAutoScroll(autoScrollInterval);
        }
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          startAutoScroll("left");
        }, TIMEOUT);
      }
    }

    return () => {
      if (autoScrollInterval) {
        stopAutoScroll(autoScrollInterval);
      }
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [autoScroll, startAutoScroll, stopAutoScroll]);

  return {
    handlePrev: handleScrollToPrevPage,
    handleNext: handleScrollToNextPage,
    hasLeftItems,
    hasRightItems,
    hidePagination
  };
};

export { useCardCarousel };
