import styled from "styled-components";
import { breakpoints, sizes } from "styles";

export const LogosBlock = styled.section`
  background: ${({ theme }) => theme.colors.brand[500]};
  padding: 34px 0;
  color: ${({ theme }) => theme.colors.white};

  @media ${breakpoints.tablet} {
    padding: 60px 0;
  }
`;

export const LogosTitle = styled.h3`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0 0 30px 0;
  line-height: 1.5;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
  }
  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["4xl"]};
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.tablet} {
    max-width: ${sizes.logosSectionWidth};
    margin: 0 auto;
  }
`;

export const LogosImageWrapper = styled.div`
  position: relative;
  margin: 8px;

  @media ${breakpoints.laptop} {
    margin: 20px 10px;
  }
`;
