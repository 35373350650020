import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const WhoIsItForContainer = styled(Container)`
  padding: 40px 16px;

  @media ${breakpoints.laptop} {
    padding: ${rem(80)};
  }
`;

export const Heading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  margin: 0;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 42px;
    margin: 0 0 40px;
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 20px;
`;

export const Card = styled.div`
  position: relative;
  min-height: 180px;
  min-width: 280px;
  @media ${breakpoints.tablet} {
    min-width: 380px;
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 25px;
  @media ${breakpoints.tablet} {
    left: 45px;
  }
`;

export const Grade = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  margin-bottom: 8px;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  }
`;

export const GradeSub = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  color: ${({ theme }) => theme.colors.white};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
  }
`;
