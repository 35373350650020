import React from "react";

import { TanyaSearchBlock } from "components/tanya-search-block/tanya-search-block";

import { FAQBlock } from "components/faq-block/faq-block";

import QuestionBankImage from "public/assets/home/Cari-bank-soal-try-out-PTS-PAS-UTBK-di-Fitur-Latihan-CoLearn.png";
import JoinToUnderstandMoreImage from "public/assets/home/Ikut-Bimbel-online-CoLearn-biar-makin-paham-materi-di-sekolah.png";
import UseAskColearnImage from "public/assets/home/Pakai-fitur-Tanya-CoLearn-kalau-buntu-ngerjain-PR-sendirian.png";

import { FeatureBlock } from "components/feature-block/feature-block";
import {
  FeatureBlockContainer,
  FeatureBlockHeading,
  FeatureBlockTitle,
  FeatureBlockWrapper
} from "components/feature-block/feature-block.styles";
import { HomeLogosBlock } from "components/home-logos-block/home-logos-block";
import { ReviewSection } from "components/review-section/review-section";
import { HomeSEO } from "seo/home.seo";
import { HomeMain } from "styles/pages/home.styles";

import { HeroLeadGenerationSection } from "components/lead-generation/hero-lead-generation-section/hero-lead-generation-section";
import { MoneyBackGuaranteeAppDownload } from "components/money-back-guarantee-app-download/money-back-guarantee-app-download";
import SubjectTopicsGrid from "components/subject-topic-grid/subject-topic-grid";
import { TrustedBySection } from "components/trusted-by-section/trusted-by-section";
import { WhoIsItForSection } from "components/who-is-it-for-section/who-is-it-for-section";
import { testimonials } from "data/testimonials";
import { useBoolean } from "hooks/use-boolean/use-boolean";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTanyaContext } from "stores/tanya/tanya-context";
import { sizes } from "styles";
import { getLeadSourceFromPath } from "utils/utils";
import { BimbelLoggedInBanner } from "components/kelas-live/bimbel-logged-in-banner/bimbel-logged-in-banner";
import { useAuth } from "stores/auth/auth-context";

const LeadGenerationModal = dynamic(
  () =>
    import(
      "components/lead-generation/lead-generation-modal/lead-generation-modal"
    )
);

const ContactMe = dynamic(() => import("components/contact-me/contact-me"));

const Home: React.FC = () => {
  const [showLeadGenerationModal, setShowLeadGenerationModal] =
    useBoolean(false);
  const {
    user: { isUserLoggedIn, userData }
  } = useAuth();

  const { contactMeForm } = useTanyaContext();
  const router = useRouter();
  const { utm_campaign, utm_source, utm_medium, utm_term, utm_content } =
    router.query;
  const leadSource = getLeadSourceFromPath(router.pathname);
  return (
    <HomeMain>
      <HomeSEO />

      {isUserLoggedIn && (
        <BimbelLoggedInBanner name={userData.primary_user.full_name} />
      )}
      {!isUserLoggedIn && (
        <HeroLeadGenerationSection
          onPriceInfoCTAClick={() => setShowLeadGenerationModal.on()}
        />
      )}

      <MoneyBackGuaranteeAppDownload />
      <TanyaSearchBlock bgTheme="light" showFloatingButton />
      <FeatureBlockContainer>
        <FeatureBlockHeading>
          Apa pun kendala belajarmu, CoLearn bisa bantu
        </FeatureBlockHeading>
        <FeatureBlockWrapper>
          <FeatureBlock
            title={
              <FeatureBlockTitle>
                Susah pahamin materi di sekolah? <br /> Ikut Bimbel online
                CoLearn!
              </FeatureBlockTitle>
            }
            desc="Cobain dulu aja, kalo nggak cocok ada garansi uang kembali!"
            imageURL={JoinToUnderstandMoreImage}
            imageAlt="Susah pahamin materi di sekolah? Ikut Bimbel online CoLearn!"
            flipMedia
            ctaTitle="Cari info Bimbel"
            ctaLink="/bimbel"
            ctaTestId="info-bimbel-middle-button"
          />
          <FeatureBlock
            title={
              <FeatureBlockTitle>
                Buntu ngerjain PR sendirian? <br /> Pakai fitur Tanya!
              </FeatureBlockTitle>
            }
            imageAlt="Buntu ngerjain PR sendirian? Pakai fitur Tanya!"
            desc="Pemecah soal Matematika, Fisika, Kimia, lengkap video pembahasannya!"
            imageURL={UseAskColearnImage}
            ctaTitle="Cari video pakai Tanya"
            ctaLink="/tanya"
            ctaTestId="info-tanya-middle-button"
          />
          <FeatureBlock
            title={
              <FeatureBlockTitle>
                Cari bank soal try out, PTS, PAS, UTBK? <br /> Di fitur Latihan
                ada semua.
              </FeatureBlockTitle>
            }
            imageAlt="Cari bank soal try out, PTS, PAS, UTBK? Di fitur Latihan ada semua."
            desc="Soal Matematika, Fisika, Kimia berbasis Kurikulum 2013 dan Kurikulum Merdeka!"
            imageURL={QuestionBankImage}
            flipMedia
            ctaTitle="Cek soal Latihan"
            ctaLink="https://l.clrn.id/e/merdeka"
            ctaRel="nofollow"
            ctaTestId="latihan-soal-middle-button"
          />
        </FeatureBlockWrapper>
      </FeatureBlockContainer>
      <WhoIsItForSection />
      <TrustedBySection />
      <ReviewSection
        testimonials={testimonials}
        title={
          <>
            Kata mereka yang udah ikut <br /> Bimbel online CoLearn 👇
          </>
        }
        width={sizes.homePageTestimonialWidth}
      />
      <SubjectTopicsGrid title="Udah Tanya soal belum hari ini?" />
      <HomeLogosBlock />
      <FAQBlock showExpandCTA />
      {contactMeForm.show && (
        <ContactMe
          metaData={contactMeForm.metaData}
          onClose={() => contactMeForm.setContactMeFormData({ show: false })}
          leadSource={leadSource}
        />
      )}
      {showLeadGenerationModal && (
        <LeadGenerationModal
          onClose={() => setShowLeadGenerationModal.off()}
          onProceed={() => {
            setShowLeadGenerationModal.off();
            contactMeForm.setContactMeFormData({
              show: true,
              metaData: {
                utm_term,
                utm_source,
                utm_medium,
                utm_campaign,
                utm_content
              }
            });
          }}
        />
      )}
    </HomeMain>
  );
};

export default Home;
