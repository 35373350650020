import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const FeatureBlockContainer = styled(Container)`
  margin: 80px auto;
  @media ${breakpoints.mobile} {
    padding: 0 8px;
    margin: 40px auto;
  }
`;

export const FeatureBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${breakpoints.tablet} {
    gap: 40px;
  }
`;

export const FeatureBlockSection = styled.section<{ $flipMedia: boolean }>`
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 30px ${({ theme }) => theme.colors.transparent.white10};
  border-radius: 10px;

  @media ${breakpoints.tablet} {
    padding: 40px;
    display: flex;
    flex-direction: ${({ $flipMedia }) => ($flipMedia ? "row-reverse" : "row")};
    align-items: center;
    justify-content: space-between;
    gap: 60px;
  }
`;

export const FeatureBlockHeading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 28px;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  text-align: center;
  margin: 0 0 40px;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 42px;
  }
`;

export const FeatureBlockContent = styled.div`
  flex: 1;
`;
export const FeatureBlockTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 22px;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  margin: 22px 0 12px 0;
  @media ${breakpoints.tablet} {
    font-size: ${rem(28)};
    line-height: 40px;
  }
`;

export const FeatureBlockDesc = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.neutral[700]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  line-height: 22px;
  margin: 0 0 20px;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
    margin: 0 0 32px;
    line-height: 30px;
  }
`;

export const FeatureBlockImageWrapper = styled.figure`
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${breakpoints.tablet} {
    max-width: 460px;
  }
`;

export const FeatureBlockCTA = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  gap: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 12px 24px;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.colors.brand[100]};
  color: ${({ theme }) => theme.colors.brand[500]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand[200]};
  }

  @media ${breakpoints.tablet} {
    display: inline-flex;
    justify-content: flex-start;
  }
`;
