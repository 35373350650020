import styled from "styled-components";
import { breakpoints } from "styles";
import { IconButton } from "styles/common/icon-button";
import { IStyledNextPrevBtnProps } from "./scroll-snap-carousel.types";

export const StyledSliderWrapper = styled.div`
  position: relative;
`;

export const StyledScroll = styled.ul<{
  containerHeight?: string;
  containerWidth?: string;
}>`
  list-style: none;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  height: ${(props) =>
    props.containerHeight ? props.containerHeight : "unset"};
  display: flex;
  align-items: center;
  width: calc(100% + calc(calc(100vw - 100%) / 2));
  scrollbar-width: none; /* Firefox */

  @media ${breakpoints.laptop} {
    margin: 0 0 0 50px;
    width: calc(
      100% + calc(calc(100vw - 100%) / 2) -
        ${({ containerWidth }) => (containerWidth ? containerWidth : "50px")}
    );
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledPrev = styled(IconButton)<IStyledNextPrevBtnProps>`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "unset" : "pointer")};
  background-color: ${({ $isDisabled, theme }) =>
    $isDisabled ? theme.colors.neutral[300] : theme.colors.brand[500]};

  @media ${breakpoints.laptop} {
    display: flex;
  }
`;

export const StyledNext = styled(StyledPrev)`
  left: unset;
  right: 0;
  color: ${({ theme }) => theme.colors.white};
`;
