import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const MoneyBackGuaranteeContainer = styled(Container)`
  padding: 40px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  gap: 16px;
  @media ${breakpoints.tablet} {
    padding: ${rem(64)};
    gap: ${rem(64)};
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.figure`
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${breakpoints.tablet} {
    max-width: 460px;
  }
`;

export const Heading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  margin: 0 0 16px;
  text-align: center;
  text-wrap: balance;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 42px;
    text-align: left;
  }
`;

export const Description = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  text-align: center;
  @media ${breakpoints.tablet} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes["xl"]};
  }
`;

export const DownloadCTAContainer = styled.div`
  margin-top: 16px;
  @media ${breakpoints.tablet} {
    margin-top: 40px;
  }
`;
