import React from "react";
import Image from "next/legacy/image";
import Forbes from "public/assets/home/logos/forbes.png";
import Techcrunch from "public/assets/home/logos/techcrunch.png";
import CNN from "public/assets/home/logos/cnn.png";
import TechInAsia from "public/assets/home/logos/techinasia.png";
import Detikinet from "public/assets/home/logos/detikinet.png";
import Tempoco from "public/assets/home/logos/tempoco.png";
import Cnbc from "public/assets/home/logos/cnbc.png";
import Kompas from "public/assets/home/logos/kompas.png";
import KrAsia from "public/assets/home/logos/krasia.png";
import { Container } from "styles/common/container";
import {
  LogosBlock,
  LogosContainer,
  LogosTitle,
  LogosImageWrapper
} from "./home-logos-block.styles";
const HomeLogosBlock: React.FC = () => (
  <LogosBlock>
    <Container>
      <LogosTitle>
        Mereka percaya bahwa CoLearn lebih dari sekedar bimbel
      </LogosTitle>
      <LogosContainer>
        <LogosImageWrapper>
          <Image
            src={Forbes}
            layout="fixed"
            alt="CoLearn featured in Forbes Indonesia "
          />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image
            src={Techcrunch}
            layout="fixed"
            alt="CoLearn featured in Techcrunch "
          />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image
            src={CNN}
            layout="fixed"
            alt="CoLearn featured in Cnn Indonesia "
          />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image
            src={TechInAsia}
            layout="fixed"
            alt="CoLearn featured in TechInAsia "
          />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image
            src={Detikinet}
            layout="fixed"
            alt="CoLearn featured in Detikinet "
          />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image
            src={Tempoco}
            layout="fixed"
            alt="CoLearn featured in Tempoco "
          />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image
            src={Kompas}
            layout="fixed"
            alt="CoLearn featured in Kompas "
          />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image src={Cnbc} layout="fixed" alt="CoLearn featured in CNBC " />
        </LogosImageWrapper>
        <LogosImageWrapper>
          <Image
            src={KrAsia}
            layout="fixed"
            alt="CoLearn featured in Kr Asia "
          />
        </LogosImageWrapper>
      </LogosContainer>
    </Container>
  </LogosBlock>
);

export { HomeLogosBlock };
