import React from "react";
import Image from "next/legacy/image";
import { Arrow } from "components/icons/arrow";
import { IFeatureBlockProps } from "./feature-block.types";
import {
  FeatureBlockDesc,
  FeatureBlockImageWrapper,
  FeatureBlockSection,
  FeatureBlockContent,
  FeatureBlockCTA
} from "./feature-block.styles";

const FeatureBlock: React.FC<IFeatureBlockProps> = ({
  imageURL,
  imageAlt,
  title,
  desc,
  ctaTitle,
  ctaLink,
  ctaRel,
  flipMedia = false,
  ctaTestId
}) => {
  return (
    <FeatureBlockSection $flipMedia={flipMedia}>
      <FeatureBlockImageWrapper>
        <Image
          src={imageURL}
          alt={imageAlt}
          layout="responsive"
          sizes="(min-width: 768px) 50vw, 100vw"
          priority
        />
      </FeatureBlockImageWrapper>
      <FeatureBlockContent>
        {title}
        <FeatureBlockDesc>{desc}</FeatureBlockDesc>
        <FeatureBlockCTA rel={ctaRel} href={ctaLink} data-testid={ctaTestId}>
          {ctaTitle}
          <Arrow />
        </FeatureBlockCTA>
      </FeatureBlockContent>
    </FeatureBlockSection>
  );
};

export { FeatureBlock };
