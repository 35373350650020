import React from "react";
import { CheckCircle } from "components/icons/check-circle";
import { theme } from "styles";
import {
  BimbelCuriculumDiv,
  BimbelGradeCuriculumText,
  ColorText
} from "components/kelas-live/bimbel-grade/bimbel-grade.styles";
import { ScrollSnapSlider } from "components/web-checkout/scroll-snap-carousel/scroll-snap-carousel";
import { useMediaQuery } from "hooks/use-media-query/use-media-query";
import {
  Card,
  CardContainer,
  Grade,
  GradeSub,
  Heading,
  TextContainer,
  WhoIsItForContainer,
  Wrapper
} from "./who-is-it-for-section.styles";
import SDImage from "public/assets/home/available-classes/Aplikasi-CoLearn-bisa-dipakai-untuk-anak-SD.svg";
import SMPImage from "public/assets/home/available-classes/Aplikasi-CoLearn-bisa-dipakai-untuk-anak-SMP.svg";
import SMAImage from "public/assets/home/available-classes/Aplikasi-CoLearn-bisa-dipakai-untuk-anak-SMA.svg";
import Image from "next/legacy/image";

const ParentComponent = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery("mobile");
  return isMobile ? (
    <ScrollSnapSlider>{children}</ScrollSnapSlider>
  ) : (
    <CardContainer>{children}</CardContainer>
  );
};

export const WhoIsItForSection: React.FC = () => {
  return (
    <Wrapper>
      <WhoIsItForContainer>
        <Heading>Aplikasi CoLearn tuh buat siapa aja, sih?</Heading>
        <ParentComponent>
          {availableGrades.map(({ img, grade, gradeSub }) => (
            <Card key={grade}>
              <Image src={img} alt={grade} layout="fill" objectFit="cover" />
              <TextContainer>
                <Grade>{grade}</Grade>
                <GradeSub>{gradeSub}</GradeSub>
              </TextContainer>
            </Card>
          ))}
        </ParentComponent>
        <BimbelCuriculumDiv>
          <CheckCircle color={theme.colors.green["600"]} />{" "}
          <BimbelGradeCuriculumText>
            Ada <ColorText> Kurikulum 2013</ColorText> dan{" "}
            <ColorText>Kurikulum Merdeka </ColorText> juga
          </BimbelGradeCuriculumText>
        </BimbelCuriculumDiv>
      </WhoIsItForContainer>
    </Wrapper>
  );
};

const availableGrades = [
  {
    img: SDImage,
    grade: "SD",
    gradeSub: "Kelas 5-6"
  },
  {
    img: SMPImage,
    grade: "SMP",
    gradeSub: "Kelas 7-9"
  },
  {
    img: SMAImage,
    grade: "SMA",
    gradeSub: "Kelas 10-12"
  }
];
